html {
    overflow-x: hidden;
    overflow-y: visible;
    scroll-snap-type: y mandatory;
}

.App {
    background-color: transparent;
    display: flex;
    flex-direction: row;
}

.nav {
    position: fixed;
    z-index: 999;
    opacity: 100%;
    width: 5vw;
    height: 100vh;
}

.smallMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    width: 5vw;
    height: 100vh;
}

.navIcon {
    color: red;
    font-size: 30px;
}

.socials {
    background-color: black;
    position: fixed;
    right: 0;
    top: 35vh;
    width: 5vw;
    height: 30vh;
    z-index: 999;
}

.smallMenuSocials {
    border-radius: 50%;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    right: 0;
    width: 5vw;
    height: 30vh;
}

.socialIcon {
    font-size: 24px;
    color: white;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.nav1 {
    background-color: white;
    color: orange;
    position: absolute;
    left: 0;
    width: 5vw;
    height: 100vh;
}

.nav2 {
    background-color: black;
    color: orange;
    position: absolute;
    left: 0;
    width: 5vw;
    height: 100vh;
}

.nav3 {
    background-color: white;
    color: orange;
    position: absolute;
    left: 0;
    width: 5vw;
    height: 100vh;
}

.nav4 {
    background-color: black;
    color: orange;
    position: absolute;
    left: 0;
    width: 5vw;
    height: 100vh;
}

.nav5 {
    background-color: white;
    color: orange;
    position: absolute;
    left: 0;
    width: 5vw;
    height: 100vh;
}

#section1 {
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    position: relative;
    left: 5vw;
    height: 100vh;
    width: 100vw;
    background-color: black;
    color: white;
    scroll-snap-align: start;
}

.about {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35vw;
    height: 100vh;
    left: 5vw;
}

.intro {
    font-size: 3vw;
}

.typing-text {
    color: red;
    font-size: 2vw;
}

.images {
    width: 65vw;
    height: 100vh;
    font-size: 50px;
}

.x1 {
    position: absolute;
    left: 70%;
    top: 30%;
}

.x2 {
    position: absolute;
    left: 45%;
    top: 70%;
}

.x3 {
    position: absolute;
    left: 55%;
    top: 50%;
}

.x4 {
    position: absolute;
    left: 90%;
    top: 75%;
}

.x5 {
    position: absolute;
    left: 75%;
    top: 60%;
}

.x6 {
    position: absolute;
    left: 55%;
    top: 20%;
}

.x7 {
    position: absolute;
    left: 85%;
    top: 5%;
}

.x8 {
    position: absolute;
    left: 40%;
    top: 5%;
}

.x9 {
    position: absolute;
    left: 65%;
    top: 85%;
}

#section2 {
    display: flex;
    flex-direction: row;
    position: relative;
    left: 5vw;
    height: 100vh;
    width: 100vw;
    background-color: white;
    color: black;
    scroll-snap-align: start;
}

.headshot {
    border-radius: 100%;
    width: 20vw;
    z-index: 999;
}

.aboutMe{
    position: relative;
    top: 10%;
    text-align: center;
    width: 50%;

}

.aboutMe p {
    color: black;
    font-size: 120%;
    padding: 5%;
    font-weight: 300;
}

.education{
    position: relative;
    top: 20%;
    width: 40%;
    text-align: center;
}

.education img{
    width: 30%;
}

.education p {
    overflow: hidden;
    overflow-wrap: break-word;
    color: black;
    font-size: 120%;
    padding: 5%;
    font-weight: 300;
}


#section3 {
    z-index: 999;
    position: relative;
    left: 5vw;
    height: 100vh;
    width: 100vw;
    background-color: black;
    color: white;
    scroll-snap-align: start;
}

.sliderCon {
    height: 100vh;
}

.slider {
    position: relative;
    left: 3vw;
    top: 10vh;
    width: 85vw;
    height: 90vh;
}

.expCard {
    overflow: hidden;
    overflow-wrap: break-word;
    bottom: 15vh;
    height: 80vh;
    background-color: black;
    border: 1px solid black;
    border-radius: 8px;
    text-align: center;
    transition: all 0.3s ease;
}

.card {
    overflow: hidden;
    overflow-wrap: break-word;
    bottom: 15vh;
    height: 80vh;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    transition: all 0.3s ease;
}

.expCard img {
    position: relative;
    top: 5%;
    left: 10vw;
    width: 10vw;
    height: 10vw;
}

.card img {
    position: relative;
    width: 100%;
    height: 40%;
}

.expCard h2 {
    top: 5%;
    position: relative;
    left: 1vw;
    color: white;
    text-align: left;
    font-weight: bolder;
}

.card h2 {
    position: relative;
    left: 1vw;
    color: black;
    text-align: left;
    font-weight: bolder;
}

.expCard h3 {
    top: 5%;
    position: relative;
    left: 1vw;
    color: grey;
    text-align: left;
}

.expCard p {
    padding: 5%;
    top: 5%;
    position: relative;
    left: 1vw;
    color: white;
    text-align: left;
}

.card p {
    padding: 5%;
    position: relative;
    left: 1vw;
    color: black;
    text-align: left;
}

.slick-current .expCard {
    font-size: 90%;
    transform: scale(1.25);
    position: relative;
    top: 10vh;
    z-index: 999;
    border-radius: 4px;
}

.slick-current .card {
    transform: scale(1.25);
    font-size: 90%;
    position: relative;
    top: 10vh;
    z-index: 999;
    border-radius: 4px;
}

.slick-slide:not(.slick-current) .expCard {
    transform: scale(0.7);
}

.slick-slide:not(.slick-current) .card {
    transform: scale(0.5);
}

.slick-dots {
    position: relative;
    top: 83vh;
    z-index: 999;
}

h3 {
    color: white;
}

#section4 {
    position: relative;
    left: 5vw;
    height: 100vh;
    width: 95vw;
    background-color: white;
    color: black;
    scroll-snap-align: start;
}

#section5 {
    position: relative;
    left: 5vw;
    height: 100vh;
    width: 95vw;
    background-color: black;
    color: white;
    scroll-snap-align: start;
}

form {
    position: relative;
    top: 20%;
    margin: 5rem auto 0;
    width: 24rem;
}

form div {
    text-align: center;
    width: inherit;
    margin-bottom: 1rem;
}

label {
    font-size: 1.75rem;
    display: block;
    color: #fff;
}

input {
    text-align: center;
    margin: 0.5rem 0;
    padding: 0 0.5rem;
    width: 80%;
    height: 3rem;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type="submit"] {
    height: 2.5rem;
    font-size: 1rem;
    color: #fff;
    background: #0971f1;
    cursor: pointer;
}

.form-control-message {
    width: 80%;
    border-radius: 8px;
    text-align: center;
    vertical-align: middle;
}

.submitButton {
    position: relative;
    left: 35%;
    width: 30%;
    height: 3rem;
    border-radius: 8px;
}

.form {
    justify-content: center;
}

p {
    margin: 0;
    color: #ff9900;
}